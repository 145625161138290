*{
  outline: none;
}

.paper-active {
  animation: active 1s infinite alternate;
  -webkit-animation: active 1s infinite alternate;
}

@keyframes active {
  from {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    color: #555555;
}
  to {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    color: #663499e8;
}
}

.spanerror {
  color: red;
  max-width: 75%;
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Container.check {
  padding: 20px 29px;
}

.App-link {
  color: #61dafb;
}

.background-blue {
  background-color: blue !important;
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  display: none;
}

.hidden {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 1240px){
  .btn-search-toogle {
    display: none !important;
  }
}


@media all and (max-width: 1240px){
  .btn-search-toogle {
    display: block !important;
  }

  .list .task-group {
    padding-top: 20px;
  }

  .pick {
    width: 100vw !important;
    z-index: 1029;
    position: absolute;
}


  .pick .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px !important;
    align-items: center;
  }

  .pick .container .MuiButtonBase-root.btn-add-aside {
    left: 0;
  }

  .Container.check {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pick .toogle-button-pick {
    display: block !important;
  }

  .car-list {
    width: 75%;
  }

  .container .aside {
    display: none;
  }

  .calendar {
    margin-left: 0;
  }

  .shadow {
    width: 90vw !important;
  }

  .shadow .task {
    width: 85vw;
  }

  .task-group .root-search {
    width: 85vw;
  }

  .floatingaction {
    display: block !important;
    position: fixed !important;
  }

}

@media all and (max-width: 713px) {

  .task.editable {
    flex-direction: column !important;
  }

  .task.editable .task-header{
    width: 100% !important;
    height: 128px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .task.editable .header-toogle{
    width: 100% !important;
    height: 160px;
    border-radius: 5px 5px 0 0;
    left: 0 !important;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
}

  .task.editable .task-body{
    position: relative;
    display: flex;
    top: 15px;
    padding: 20px;
    max-height: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .task.editable .taskform{
    width: 100%;
    padding: 0 !important;
  }

  .task.editable .task-body .header-line{
    display: flex;
    justify-content: center;
  }

  .task.editable .line-pointer{
    left: 44.5%;
  }

  .task.editable .task-actions{
    left: 62%;
  }

  .close-task {
    color: #fff;
  }

  .pick .container {
    margin: none !important;
  }
  .pick {
    max-width: 100%;
    min-width: 100% !important;
  }
  .taskform {
    position: relative !important;
    top: 0 !important;
  }

  .taskform .wrapper {
    margin-top: 5px !important;
  }

}

@media all and (max-width: 638px) {
  .MuiDialogContent-root.imagesList {
    padding: 75px !important;
    text-align: center;
  }
  .MuiTypography-root.MuiTypography-h6 {
    text-align: center !important;
  }
  .task-body {
    max-width: 100% !important;
    text-align: center;
  }
  .imgPreview {
    display: none !important;
  }

  .icon-upload {
    display: none !important;
  }

  .task-header, .header-toogle {
    width: 56% !important;
  }

}

@media all and (max-width: 459px) {

  .task {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .DayPicker-Week .DayPicker-Day {
    padding: 1em !important;
  }

  .kLGapG {
    height: 100vh !important;
    width: 100vw !important;
    border-radius: 0 !important  ;
    -webkit-border-radius: 0 !important  ;
    -moz-border-radius: 0 !important  ;
    -ms-border-radius: 0 !important  ;
    -o-border-radius: 0 !important  ;
  }

  .task-body .task-actions  {
    display: none !important;
  }

  .task.editable .task-header {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
    margin: 15px 0;
  }

  .task.editable .header-toogle {
    height: 215px;
  }

  .task.editable .task-body {
    top: 48px;
  }

  .taskform {
    height: 180px !important;
  }

  .taskform input[type="file"] {
    width: 66%;
  }

}

@media all and (max-width: 320px) {
  .DayPicker-Week .DayPicker-Day {
    padding: 0.7em !important;
  }
}


/** Toastfy styling **/
.Toastify__toast.Toastify__toast--success {
  background: rgb(33, 192, 94) !important;
}