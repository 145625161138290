.progress, .schedulesnull{
    width: 100%;
    height: 80%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    flex-direction: column;
    font-size: 1.5rem;
    user-select: none;
}

.progress.active {
    display: block !important;
    display: flex !important;
}

.loader {
    width: 11.7em;
    height: 4.2em;
    font-size: 16px;
    color: #F57C00;
    position: relative;
}

.car {
    position: absolute;
    width: inherit;
    height: 2em;
    background-color: currentColor;
    top: 1.5em;
    border-radius: 0 5em 1em 0 / 0 4em 1em 0;
}

.car-content-loader.active svg {
    width: 550px;
    display: block;
}
.car-content-loader svg {
    width: 550px;
    display: none;
}

@keyframes run {
    0% {
        transform: translate(0.2em, 0.1em) rotate(0deg);
    }

    20% {
        transform: translate(0.1em, 0.2em) rotate(1deg);
    }

    40% {
        transform: translate(0.1em, -0.1em) rotate(-1deg);
    }

    60% {
        transform: translate(-0.1em, 0.2em) rotate(0deg);
    }

    80% {
        transform: translate(-0.1em, 0.1em) rotate(1deg);
    }

    100% {
        transform: translate(0.2em, 0.1em) rotate(-1deg);
    }
}

.car::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -0.5em;
    border: 0.6em solid transparent;
    border-left-width: 0;
    border-right-color: currentColor;
    transform-origin: left;
    transform: rotate(-45deg);
}

.body {
    position: absolute;
    width: 7.5em;
    height: 3.5em;
    box-sizing: border-box;
    border: 0.4em solid;
    border-radius: 3em 4.5em 0 0 / 3em 4em 0 0;
    top: -1.5em;
    left: 1.2em;
}

.body::before {
    content: '';
    position: absolute;
    width: 3.5em;
    height: inherit;
    background-color: currentColor;
    border-top-left-radius: inherit;
    left: -0.4em;
    top: -0.4em;
}

.wheels::before,
.wheels::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 2.6em;
    height: 2.6em;
    background-color: #FFF;
    border-radius: 50%;
    bottom: -1em;
    border: 0.3em solid #333;
    background-image: 
        linear-gradient(
            135deg,
            transparent 45%,
            currentColor 46%, currentColor 54%,
            transparent 55%
        ),
        linear-gradient(
            90deg,
            transparent 45%,
            currentColor 46%, currentColor 54%,
            transparent 55%
        ),
        linear-gradient(
            45deg,
            transparent 45%,
            currentColor 46%, currentColor 54%,
            transparent 55%
        ),
        linear-gradient(
            0deg,
            transparent 45%,
            currentColor 46%, currentColor 54%,
            transparent 55%
        ),
        radial-gradient(
            currentColor 29%,
            transparent 30%, transparent 50%,
            currentColor 51%
        );
    animation: rotating 0.5s linear infinite;
}

.wheels::before {
    left: 1.2em;
}

.wheels::after {
    right: 0.8em;
}

@keyframes rotating {
    to {
        transform: rotate(1turn);
    }
}

.strikes {
    position: absolute;
    width: 1em;
    height: inherit;
    left: -1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.strikes span {
    height: 0.1em;
    background-color: #F57C00;
    animation: drift 0.2s linear infinite;
    animation-delay: calc(var(--n) * -0.05s);
}

@keyframes drift {
    from {
        transform: translate(3.5em);
    }

    to {
        transform: translate(-8em);
        filter: opacity(0);
    }
}

.strikes span:nth-child(1) {
    --n: 1;
}

.strikes span:nth-child(2) {
    --n: 2;
}

.strikes span:nth-child(3) {
    --n: 3;
}

.strikes span:nth-child(4) {
    --n: 4;
}

.strikes span:nth-child(5) {
    --n: 5;
}




#loader {
    animation: loader 5s cubic-bezier(.8,0,.2,1) infinite;
    height: 40px;
    width: 41px;
    position: absolute;
    top:calc(50% - 20px);
    left:calc(50% - 20px);
  }
  @keyframes loader {
    90% { transform: rotate(0deg); }
    100% { transform: rotate(180deg); }
  }

  #top {
    animation: top 5s linear infinite;
    border-top: 20px solid #3f51b5eb;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    height: 0px;
    width: 1px;
    transform-origin: 50% 100%;
  }

  @keyframes top {
    90% { transform: scale(0); }
    100% { transform: scale(0);}
  }

  .task-user #bottom {
    border-bottom: 20px solid #7446a2;
  }

  .task-user #line {
    border-left: 1px dotted #7446a2;
  }

  .task-user #top {
    border-top: 20px solid #7446a2;
  }

  #bottom {
    animation: bottom 5s linear infinite;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #3f51b5eb;
    border-left: 20px solid transparent;
    height: 0px;
    width: 1px;
    transform: scale(0);
    transform-origin: 50% 100%;
  }

  @keyframes bottom {
    10% { transform: scale(0); }
    90% { transform: scale(1); }
    100% { transform: scale(1); }
  }

  #line {
    animation: line 5s linear infinite;
    border-left: 1px dotted #3f51b5eb;
    height: 0px;
    width: 0px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  @keyframes line {
    10% { height: 20px; }
    100% { height: 20px; }
  }

  .loading-with-content {
    position: absolute;
    width: 100%;
    height: 110%;
    max-width: 650px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #b8c7cc;
    z-index: 1;
    opacity: 0.6;
  }

  .loading-with-content.active {
    display: flex;
  }