.formSchedule .MuiFormControl-root,
.formSchedule
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-align-items-xs-center {
    margin-right: 20px;
    margin-left: 20px;
}

.formSchedule {
  display: flex;
  flex-direction: column;
}

.MuiDialogTitle-root {
  border-left: 4px solid #3f51b5;
  margin-top: 15px !important;
  padding: 3px 31px !important;
}

.MuiDialogContent-root {
  padding: 23px 65px 20px 65px !important;
}

.formSchedule .MuiFormControl-root {
  margin-bottom: 25px;
}

.MuiDialogActions-root {
  justify-content: center;
  flex-direction: column;
}

.btn-send {
  width: 50% !important;
}

.disabled {
  display: none !important;
}

.select {
  position: relative;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  padding: 10px 10px 10px 0;
  font-size: 1rem;
  border-radius: 0;
  border: none;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none;
}

.select:after {
  position: absolute;
  top: 25px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
  color: rgba(0, 0, 0, 0.26);
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-label,
.select-text:valid ~ .select-label {
  color: rgba(0, 0, 0, 0.54);
  top: -12px;
  transition: 0.2s ease all;
  font-size: 1rem;
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
}

.select-bar:before,
.select-bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #3f51b5;
  transition: 0.2s ease all;
}

.select-bar:before {
  left: 50%;
}

.select-bar:after {
  right: 50%;
}

/* active state */
.select-text:focus ~ .select-bar:before,
.select-text:focus ~ .select-bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.wrapper .button {
    width: 100%;
}
