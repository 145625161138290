@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
 font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: "#dfdfdf";
}

.MuiDialog-paperWidthSm {
  min-width: 600px;
}

.schedulesnull.active {
  display: block;
  display: flex;
}

.schedulesnull.disable {
  display: none;
}



/* BASE */
.calendar__row {
  display: flex;
  flex-direction: row;
}

.calendar__cell {
  flex: 1;
}

/* THEME */
body {
  margin: 0;
}

body, html {
  background: #fff;
  color: #555;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

* {
  box-sizing: border-box;
  outline: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  background-color: #b8c7cc;
}

.calendar {
  padding: 0;
  margin-left: 71px;
  height: 100vh;
}

.calendar__body {
  position: relative;
}

.calendar__cell {
  height: 40px;
}

.calendar__cell--appointment {
  display: flex;
  position: relative;
}

.calendar__cell--time-spacing {
  flex: 6px 0 0;
  border-right: none;
}

.calendar__cell--day-of-week {
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendar__cell--day-of-week__day {
  font-size: 1.1em;
  color: #394152;
}

.calendar__cell--day-of-week__date {
  font-size: 2.5em;
}

.calendar__cell--day-of-week--today .calendar__cell--day-of-week__day, .calendar__cell--day-of-week--today .calendar__cell--day-of-week__date {
  color: #fc3d39;
}

.calendar__cell--weekend {
  background: #fafafa;
}

.calendar__cell--time-col {
  flex: 50px 0 0;
}

.calendar__cell--time {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1em;
  transform: translateY(-50%);
  font-size: 0.9em;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar__cell--time:after {
  content: "";
  background: #ddd;
  height: 4px;
  width: 4px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-radius: 50%;
}

.calendar__row--deco-last-row {
  height: 0;
}
.calendar__row--deco-last-row .calendar__cell--appointment {
  height: 0;
}

.calendar__appointment {
  background: #e0f6d8;
  color: #0c881d;
  border: #bfecaf solid 1px;
  border-left: #76db56 solid 2px;
  padding: 4px 6px;
  font-size: 12px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: 0;
  z-index: 1;
  min-height: 100%;
}

.calendar__appointment__name {
  font-weight: bold;
}

.calendar__current-time {
  height: 1px;
  background-color: #fc3d39;
  position: absolute;
  right: 0px;
  left: 10px;
  z-index: 1;
}

/* TODO: La hora actual está quedando fuera del bloque
 * del calendario.
 */
.calendar__current-time__text {
  font-family: "Roboto Condensed", sans-serif;
  color: #fc3d39;
  position: absolute;
  top: 50%;
  transform: translate3d(-100%, -50%, 0);
  margin-left: -2px;
}

/* INTERACTIONS */
.calendar__row:hover .calendar__cell--time {
  color: #555;
}
.calendar__row:hover .calendar__cell--time:after {
  background-color: #aaa;
}

.calendar__row:hover + .calendar__row .calendar__cell--time {
  color: #555;
}
.calendar__row:hover + .calendar__row .calendar__cell--time:after {
  background-color: #aaa;
}




.error-message {
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.error-message img{
  width: 50%;
}





/** PRELOADER AUTH ANIMATION **/
.wp{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
                              
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%); 
  width: 100vw;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgb(29,25,124);
  background: linear-gradient(325deg, rgba(29,25,124,0.7007003484987745) 0%, rgba(22,142,205,0.7035014689469538) 100%);
}

.wp img {
  width: 50vw;
  max-width: 250px;
}

.wp .text {
  margin-bottom: 20px;
  color: #fff;
  font-size: 1.9rem;
}

.circle{
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
  transform-origin: bottom center;
  position: relative;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: #fff;
  }
  50%{
    transform: translateY(50px);
    background-color: #d95527;
  }
  100%{
    transform: translateY(0px);
    background-color: #fff;
  }
}
.circle-1{
  animation-delay: 0.1s;
}
.circle-2{
  animation-delay: 0.2s;
}
.circle-3{
  animation-delay: 0.3s;
}
.circle-4{
  animation-delay: 0.4s;
}
.circle-5{
  animation-delay: 0.5s;
}
.circle-6{
  animation-delay: 0.6s;
}
.circle-7{
  animation-delay: 0.7s;
}
.circle-8{
  animation-delay: 0.8s;
}