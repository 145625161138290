.MuiDialogActions-spacing > * + * {
    margin-left: 0 !important;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-actions {
    position: absolute;
    right: 0;
    cursor: not-allowed;
}

.listItem p {
    margin-left: 20px;
    font-weight: bold;
    width: 30%;
}

.listItem p.plate {
    text-align: left;
}

.list-progress.disable{
    display: none;
}

.list-progress.active{
    display: block;
}

.list-progress.active{
    width: 90%;
    justify-content: center;
    align-items: center;
    height: 250px;
    top: 0;
    display: flex !important;
}


.MuiCircularProgress-root {
    width: 80px !important;
    height: 80px !important;
}

.controls {
    width: 100%;
    display: inline-flex;
    height: 70px;
    padding: 0 13%;
}

.modalConfirm.disable {
    display: none;
}

.modalConfirm {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalConfirm.active {
    display: block;
    display: flex;
}

.modalConfirm .MuiSvgIcon-root {
    font-size: 5rem;
    color: #ffae42;
}

.modalConfirm .MuiButton-contained {
    margin: 0 10px;
    padding: 12px 22px;
    width: 100% !important;
}


.switch-modal-top {
    position: absolute;
    right: 0;
}

@media all and (max-width: 600px) {
    .MuiDialog-paperWidthFalse,
    .MuiDialog-paperScrollPaper {
        width: 100% !important;
        max-width: 100vw !important;
        margin: 0 !important;
        height: 100vh !important;
        max-height: 100% !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
    }
}